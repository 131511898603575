@media (max-width: 575.98px) {

}

.bg-honey {
  background-color: #f8cf44;
}

.text-honey {
  color: #f8cf44;
}

.bboard-logo {
  width: 350px;
}

.bw {
  filter: grayscale(1);
}

.history-arrow {
  position: absolute;
  cursor: pointer;
  opacity: .7;
  transition: opacity .2s linear!important;
}

.history-arrow:hover {
  opacity: 1;
}

.history-arrow-l {
  left: 5px;
}

.history-arrow-r {
  right: 5px;
}

.scrollable {
  overflow-y: auto;
  max-height: 100%;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.scrollable::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

.fader {
  position: absolute;
  left: 5px;
  right: 5px;
  height: 20px;
  opacity: .5;
}

.block-info {
  position: absolute;
  min-width: 300px;
  bottom: -33px;
  margin: 0 auto;
  border: 5px solid #fff;
  background-color: #000;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

@media (max-width: 350px) {
  .block-info {
    position: absolute;
    min-width: calc(100% - calc(var(--bs-gutter-x)));
  }
}

.fader-up {
  top: 5px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: linear-gradient(0,rgba(0,0,0,0) 0,rgb(0,0,0) 60%, rgb(0,0,0) 100%);
}

.fader-down {
  bottom: 5px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: linear-gradient(180deg,rgba(0,0,0,0) 0,rgb(0,0,0) 60%, rgb(0,0,0) 100%);
}

.bboard-billboard {
  width: 100%;
  border: 5px solid #fff;
  background-color: #000;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inline-input {
  width: 1rem;
  background-color: inherit;
  border: 0;
  outline: none;
}

.badge {
  white-space: inherit !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.br-disable br{
  display: none;
}

.mb-5-plus {
  margin-bottom: calc(3rem + 37px);
}

.input-honey {
  min-width: 2rem;
  border: 2px solid;
  border-radius: 15px;
}

.break-all {
  word-break: break-word;
}